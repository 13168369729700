import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const MobileLanding = SuspenseWrapper(
  lazy(() => import("components/mobile-landing/MobileLanding"))
);

const MobileLandingRoutes = {
  path: "mobile-landing",
  element: <Outlet />,
  children: [
    {
      path: "",
      index: true,
      element: <MobileLanding />,
    },
  ],
};

export default MobileLandingRoutes;
