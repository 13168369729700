import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const EnqStageList = SuspenseWrapper(
  lazy(() => import("components/admission/EnqStageList"))
);
const EnqStatusList = SuspenseWrapper(
  lazy(() => import("components/admission/EnqStatusList"))
);
const EnqFormList = SuspenseWrapper(
  lazy(() => import("components/admission/EnqFormList"))
);
const EnqForm = SuspenseWrapper(lazy(() => import("components/admission/EnqForm")));
const EnqFormView = SuspenseWrapper(
  lazy(() => import("components/admission/EnqFormView"))
);
const EnquirySettingsForm = SuspenseWrapper(
  lazy(() => import("components/admission/EnqSettingsForm"))
);

const DesignFormList = SuspenseWrapper(
  lazy(() => import("components/admission/DesignFormList"))
);

const DesignForm = SuspenseWrapper(
  lazy(() => import("components/admission/DesignForm"))
);

const AdmissionEntryInit = SuspenseWrapper(
  lazy(() => import("components/admission/AdmissionEntryInit"))
);

const AdmissionDesignList = SuspenseWrapper(
  lazy(() => import("components/admission/AdmissionDesignList"))
);

const AdmissionEntryList = SuspenseWrapper(
  lazy(() => import("components/admission/AdmissionEntryList"))
);

const AdmissionStatusMasterList = SuspenseWrapper(
  lazy(() => import("components/admission/AdmissionStatusMasterList"))
);

const AdmissionRoutes = {
  path: "admission",
  element: <Outlet />,
  children: [
    {
      index: true,
      path: "enqstage-list",
      element: <EnqStageList />,
    },
    {
      path: "enqstatus-list",
      element: <EnqStatusList />,
    },
    {
      path: "enqform",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <EnqFormList />,
        },
        {
          path: "add",
          element: <EnqForm />,
        },
        {
          path: "edit/:hash",
          element: <EnqForm />,
        },
        {
          path: "view/:hash",
          element: <EnqFormView />,
        },
      ],
    },
    {
      path: "settings",
      element: <EnquirySettingsForm />,
    },

    {
      path: "form",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <DesignFormList />,
        },
        {
          path: "add",
          element: <DesignForm pageMode="N" />,
        },
        {
          path: "edit/:hash",
          element: <DesignForm pageMode="E" />,
        },  
        {
          path: "view/:hash",
          element: <DesignForm pageMode="V" />,
        },      
      ],
    },
    {
      path: "application",
      element: <Outlet />,
      children: [
        {
          path: "sett-list",
          element: <AdmissionDesignList />,
        },
        {
          path: "list/:hash",
          element: <AdmissionEntryList />,
        }, 
        {
          path: "add/:hash",
          element: <AdmissionEntryInit pageMode="N" />,
        },
        {
          path: "edit/:hash",
          element: <AdmissionEntryInit pageMode="U"  />,
        },  
        {
          path: "view/:hash",
          element: <AdmissionEntryInit pageMode="V" />,
        },      
      ],
    },
    {
      path: "status-list",
      element: <AdmissionStatusMasterList pageMode="M" />,
    },
    {
      path: "status-list/:hash",
      element: <AdmissionStatusMasterList pageMode="F" />,
    },
    
  ],
};

export default AdmissionRoutes;
