import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "routes/SuspenseWrapper";

const HelpMasterList = SuspenseWrapper(
  lazy(() => import("components/help/HelpMasterList"))
);
const HelpContentView = SuspenseWrapper(
  lazy(() => import("components/help/HelpContentView"))
);
const ContentDetails = SuspenseWrapper(
  lazy(() => import("components/help/contentDetails"))
);


const HelpContentRoutes = {
  path: "help",
  element: <Outlet />,
  children: [
    {
      path: "master/list",
      element: <HelpMasterList />,
    },
    {
      path: "content/view/:hash",
      element: <HelpContentView />,
    },
    {
      path: "content-detail/:id",
      element: <ContentDetails/>,
    },
  ],
};

export default HelpContentRoutes;
