import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Api from "./ajax";

import PageLoader from "./components/common/PageLoader";

import { setInstConfig } from "./features/instituteConfig";
import { setCurrentUser } from "./features/currentUser";
import { setRoleActionDisable } from "./features/roleActionDisable";
import { setDashboardAccess } from "./features/dashboardAccess";
import { setIsMobileNoMasked } from "./features/IsMobileNoMasked";
import { setInstExclusiveMenuList } from "./features/instExclusiveMenuList";
import { useDispatch } from "react-redux";

import InstRoutes from "routes";
import useStorageSync from "hooks/useStorageSync";

const UnderMaintenance = lazy(() => import("./components/UnderMaintenance"));
const Login = lazy(() => import("./components/Login"));
const PublicLogin = lazy(() => import("./components/PublicLogin"));
const AdmissionPublicEntryInit = lazy(() => import("./components/admission/AdmissionPublicEntryInit"));
const AdmissionPublicRef = lazy(() => import("./components/admission/AdmissionPublicRef"));
const AdmissionPublicApplnList = lazy(() => import("./components/admission/AdmissionPublicApplnList"));

const api = new Api();

const MainApp = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const { setValue } = useStorageSync('inst_id');

  const dispatch = useDispatch();

  useEffect(() => {
    api.post(
      "/has-auth",
      { inst_id: localStorage.getItem("inst_id") },
      (res) => {
        if (res.mm !== undefined && res.mm !== null && res.mm === true) {
          window.location.href = "/under-maintenance";
        } else {
          if (res.s) {
            dispatch(setInstConfig(res.d.inst_config));
            dispatch(setCurrentUser(res.d.current_user));
            dispatch(setRoleActionDisable(res.d.role_action_disable));
            dispatch(setDashboardAccess(res.d.dashboard_access));
            dispatch(setIsMobileNoMasked(res.d.is_mobileno_masked));
            dispatch(setInstExclusiveMenuList(res.d.instExclusiveActionAllList))

            setValue(res.d.current_user["inst_id"])
            localStorage.setItem("inst_id", res.d.current_user["inst_id"]);
            setIsLoggedIn(true);
          } else if (!res.s && (res.f === 1006 || res.f === "1006")) {
            localStorage.setItem("inst_id", null);
            localStorage.setItem("menu_id", null);
            setIsLoggedIn(true);
            window.location.href = "/institution-list";
          } else setIsLoggedIn(false);
        }
      }
    );
  }, [dispatch]);

  let routeComponents;

  if (isLoggedIn === null) {
    routeComponents = <PageLoader />;
  } else {
    if (!isLoggedIn) {
      routeComponents = (
        <BrowserRouter>
          <Routes>
            <Route
              path="sign-in"
              element={
                <Suspense fallback={<PageLoader />}>
                  <Login authenticate={() => setIsLoggedIn(true)} />
                </Suspense>
              }
            />
            <Route
              path="under-maintenance"
              element={
                <Suspense fallback={<PageLoader />}>
                  <UnderMaintenance />
                </Suspense>
              }
            />
            <Route
              path="p/:hash"
              element={
                <Suspense fallback={<PageLoader />}>
                  <PublicLogin />
                </Suspense>
              }
            />
            <Route
              path="p/add/:hash/:hash1"
              element={
                <Suspense fallback={<PageLoader />}>
                  <AdmissionPublicEntryInit pageMode="N" />
                </Suspense>
              }
            />
             <Route
              path="p/edit/:hash/:hash1"
              element={
                <Suspense fallback={<PageLoader />}>
                  <AdmissionPublicEntryInit pageMode="U" />
                </Suspense>
              }
            />
            <Route
              path="p/add_ref/:hash/:hash1"
              element={
                <Suspense fallback={<PageLoader />}>
                  <AdmissionPublicRef pageMode="N" />
                </Suspense>
              }
            />
            <Route
              path="p/edit_ref/:hash/:hash1"
              element={
                <Suspense fallback={<PageLoader />}>
                  <AdmissionPublicRef pageMode="U" />
                </Suspense>
              }
            />
            <Route
              path="p/list/:hash"
              element={
                <Suspense fallback={<PageLoader />}>
                  <AdmissionPublicApplnList />
                </Suspense>
              }
            />
            <Route path="*" element={<Navigate to="/sign-in" />}></Route>
          </Routes>
        </BrowserRouter>
      );
    } else {
      routeComponents = (
        <Suspense fallback={<PageLoader />}>
          <BrowserRouter>
            <InstRoutes />
          </BrowserRouter>
        </Suspense>
      );
    }
  }

  return <>{routeComponents}</>;
};

export default MainApp;
